<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">偵測觸發紀錄功能</h4>
      <card>
        <!-- <h4 slot="header" class="card-title"></h4> -->

        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="handleAllDetectDelete()"
          >移除所有偵測觸發紀錄</el-button
        >
        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="exportDetectFile"
          >匯出</el-button
        >
      </card>
      <!-- end card -->
    </div>
    <div class="col-md-12">
      <h4 class="title">偵測觸發紀錄</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select
            class="select-default"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input
              class="input-sm"
              placeholder="Search"
              v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split"
            >
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            v-loading="loading"
            class="table-striped"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>
            <el-table-column min-width="55" prop="photo" label="照片">
              <template slot-scope="scope">
                <img :src="scope.row.photo" />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <div class="col-sm-6">
          <p-pagination
            class="pull-right"
            v-model="pagination.currentPage"
            @change-page="handleCurrentChange"
            :per-page="pagination.perPage"
            @per-page="handleSizeChange"
            :total="pagination.total"
          >
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import swal from "sweetalert2";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Loading,
  Button,
} from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import axios from "axios";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Loading);
Vue.use(Button);
export default {
  components: {
    PPagination,
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      tableColumns: [
        {
          prop: "id",
          label: "序號",
          minWidth: 30,
        },
        {
          prop: "camera",
          label: "攝影機",
          minWidth: 50,
        },
        {
          prop: "recog_time",
          label: "辨識時間",
          minWidth: 80,
        },
      ],
      tableData: [],
      loading: true,
    };
  },
  watch: {
    searchQuery: function () {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const params = {};
      params.limit = this.pagination.perPage;
      params.index = this.pagination.currentPage;
      params.q = this.searchQuery ? this.searchQuery : undefined;

      this.loading = true;
      axios.get(`/detect`, { params }).then((res) => {
        this.tableData = res.data.rows;
        this.pagination.total = res.data.count;
        this.loading = false;
      });
    },
    handleSizeChange(size) {
      this.pagination.perPage = size;
      this.getData();
    },
    handleCurrentChange(currentPage) {
      this.pagination.currentPage = currentPage;
      this.getData();
    },
    handleSearchQuery() {
      this.getData();
    },
    handleAllDetectDelete() {
      swal
        .fire({
          title: "是否移除",
          showDenyButton: true,
          confirmButtonText: "是",
          denyButtonText: "否",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios.delete(`/detect`).then(
              (res) => {
                this.$notify({
                  title: "移除所有偵測觸發紀錄成功",
                  message: res.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-check-2",
                  type: "success",
                });
                swal.fire("移除所有偵測觸發紀錄成功!", "", "success");
                this.timeOutReload = setTimeout(() => {
                  this.getData();
                }, 500);
              },
              (res) => {
                this.$notify({
                  title: "移除所有偵測觸發紀錄失敗",
                  message: res.response.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-alert-circle-i",
                  type: "danger",
                });
                swal.fire("移除所有偵測觸發紀錄失敗", "", "error");
              }
            );
          } else if (result.isDenied) {
            swal.fire("所有偵測觸發紀錄尚未移除", "", "info");
          }
        });
    },
    async exportDetectFile() {
      const params = {};
      params.encode = "big5";
      swal.fire("匯出所有偵測觸發紀錄檔案 請稍候 ...", "", "success");
      let fileData = await axios({
        url: "/detect/export",
        responseType: "blob",
        params,
      });
      let URL = window.URL || window.webkitURL;
      let downloadUrl = URL.createObjectURL(fileData.data);
      let a = document.createElement("a");
      a.download = "匯出所有偵測觸發紀錄檔案.zip";
      a.href = downloadUrl;
      a.click();
      a = null;
      downloadUrl && URL.revokeObjectURL(downloadUrl);
    },
  },
};
</script>
<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
</style>
