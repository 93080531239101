<template>
  <div>
    <div class="col-md-12">
      <h4 class="title">派工列表 - 早班加班</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-header"></div>
      <div class="card-body row">

        <div class="col-sm-6"></div>
        <div class="col-sm-12 mt-2">
          <el-table ref="filterTable" :data="tableData1" style="width: 100%">
            <el-table-column prop="start_area" label="施工區域" sortable></el-table-column>
            <el-table-column prop="type" label="施工內容" sortable></el-table-column>
            <el-table-column prop="start_car_user_list" label="施工機具">
              <template slot-scope="scope">
                <div type="info" v-for="(car_user, index) in scope.row.start_car_user_list" :key="index">
                  {{ car_user.sn }} - {{ car_user.user_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="end_car_user_vehicle_list" label="施工車輛">
              <template slot-scope="scope">
                <div type="info" v-for="(car_user, index) in scope.row.end_car_user_vehicle_list" :key="index">
                  {{ car_user.sn }} - {{ car_user.user_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="end_area" label="土尾區域" sortable> </el-table-column>
            <el-table-column prop="end_car_user_list" label="土尾車輛">
              <template slot-scope="scope">
                <div type="info" v-for="(car_user, index) in scope.row.end_car_user_list" :key="index">
                  {{ car_user.sn }} - {{ car_user.user_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="功能" width="100">
              <template slot-scope="scope">
                <el-button @click="handleEdit(scope.row)" type="text" size="small">編輯</el-button>
                <el-button @click="handleDelete(scope.row)" type="text" size="small">移除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <div v-if="dialogVisible">
      <soilDispatchDialog :visible="dialogVisible" @close-dialog="handleCloseDialog" :current-index="currentIndex"
        :current-data="currentData" :dialog-status="dialogStatus" />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import swal from "sweetalert2";
import axios from "axios";
import soilDispatchDialog from "./SoilDispatchDialog.vue";

export default {
  props: ['soilDispatchMorningOvertime'],
  components: {
    soilDispatchDialog,
  },
  computed: {
  },
  props: {
    id: {
      type: String,
    }
  },
  data() {
    return {
      tableData1: [],
      dialogVisible: false,
      currentIndex: 0,
      currentData: {},
      dialogStatus: "info",
      // loading: true,
    };
  },
  mounted() {
    this.getData();
  },
  watch: {

  },
  methods: {
    // formatter(row, column) {
    //   return row.address;
    // },
    filterTag(value, row) {
      return row.tag === value;
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    async getData() {
      const params = {};

      this.loading = true;
      try {
        const res = await axios.get(`/soil/dispatch/detail/${this.id}/0`, { params });
        this.tableData1 = res.data.rows;

        this.loading = false;
      } catch (error) {
        console.log(error)
      }
    },
    handleWorkDay0Add(date) {
      this.currentData = {
        work_day: 0,
      };
      this.dialogVisible = true;
      this.dialogStatus = "add";
    },
    handleWorkDay1Add(date) {
      this.currentData = {
        work_day: 1,
      };
      this.dialogVisible = true;
      this.dialogStatus = "add";
    },
    handleWorkDay2Add(date) {
      this.currentData = {
        work_day: 2,
      };
      this.dialogVisible = true;
      this.dialogStatus = "add";
    },
    // handleInfo(index, row) {
    //   this.currentData = row;
    //   this.dialogVisible = true;
    //   this.dialogStatus = "info";
    // },
    handleEdit(row) {
      this.currentData = row;
      this.dialogVisible = true;
      this.dialogStatus = "edit";
    },
    handleCloseDialog({ visible }) {
      this.dialogVisible = visible;
      this.timeOutReload = setTimeout(() => {
        this.getData();
      }, 500);
    },
    handleDelete(row) {
      swal
        .fire({
          title: "是否移除",
          showDenyButton: true,
          confirmButtonText: "是",
          denyButtonText: "否",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`/soil/dispatch/detail/${row.id}`)
              .then(
                (res) => {
                  this.$notify({
                    title: "移除派工成功",
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                  swal.fire("移除派工成功!", "", "success");
                  this.timeOutReload = setTimeout(() => {
                    this.getData();
                  }, 500);
                },
                (res) => {
                  this.$notify({
                    title: "移除派工失敗",
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                  swal.fire("移除派工失敗", "", "error");
                }
              );
          } else if (result.isDenied) {
            swal.fire("派工尚未移除", "", "info");
          }
        });
    },
    handleRefresh() {
      this.timeOutReload = setTimeout(() => {
        this.getData();
      }, 500);
    }
  },
};
</script>
<style lang="scss">
</style>
