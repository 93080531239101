<template>
  <div class="row">
    <el-dialog
      title="派車司機系統"
      :visible.sync="visible"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="司機" required>
          <el-row :gutter="10">
            <el-radio-group v-model="form.user_name">
              <el-col :span="6" v-for="item in users" :key="item.name">
                <el-radio :label="item.name" :disabled="item.disabled">{{
                  item.name
                }}</el-radio>
              </el-col>
            </el-radio-group>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" @click="handleClear('form')">清 除</el-button>
        <el-button type="danger" @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('form')"
          >確 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import {
  Dialog,
  Button,
  Form,
  FormItem,
  Input,
  Select,
  DatePicker,
  RadioGroup,
  Radio,
  Row,
  Col,
} from "element-ui";
import { Card } from "src/components/UIComponents";
import axios from "axios";
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(DatePicker);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Row);
Vue.use(Col);

export default {
  components: {
    Card,
  },
  name: "soil-car-dialog",
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
    currentAll: {
      type: Array,
      require: true,
      default: [],
    },
    currentData: {
      type: Object,
      require: true,
      default: {},
    },
    dialogStatus: {
      type: String,
      require: true,
      defalut: "info",
    },
    soilDispatchListId: {
      type: String,
    },
  },
  data() {
    return {
      // soilDispatchListId: 1,
      date: new Date().toISOString().split("T")[0],
      leaves: {},
      form: {},
      rules: {},
      users: [],
      devices: [],
      workDayOptions: [
        {
          value: 0,
          label: "早班加班(6:30)",
        },
        {
          value: 1,
          label: "早班(7:00)",
        },
        {
          value: 2,
          label: "晚班加班(19:00)",
        },
      ],
    };
  },
  async created() {
    this.form = { ...this.currentData };
    // this.getDevices();
    await this.getDate();
    await this.getLeaves();
    await this.getUsers();
  },
  methods: {
    async getDate() {
      let resDispatch = await axios.get(`/soil/dispatch?id=${this.soilDispatchListId}`)
      this.date = resDispatch.data.rows[0].date
    },
    async getLeaves() {
      const start = moment(this.date).startOf('day').toISOString()
      const end = moment(this.date).endOf('day').toISOString()
      const resLeave = await axios.get(`/leave?start=${start}&end=${end}`)
      this.leaves = resLeave.data
    },
    async getUsers() {
      axios.get(`/face/user/name?index=1&limit=200`).then(async(res) => {
        this.users = res.data.rows;
        for (let index = 0; index < this.users.length; index++) {
          const user = this.users[index];

          for (let j = 0; j < this.currentAll.length; j++) {
            const element = this.currentAll[j];

            if (user.name === element.user_name) {
              user.disabled = true;
            }
          }
          for (let j = 0; j < this.leaves.length; j++) {
            const element = this.leaves[j];

            if (user.name === element.name) {
              user.disabled = true;
            }
          }
        }
      });
    },
    handleClose() {
      this.$emit("close-dialog", { visible: !this.visible });
    },
    handleSubmit(formName) {
      console.log("form");
      console.log(this.form);

      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogStatus === "add") {
            axios.post(`/soil/car/${this.soilDispatchListId}`, this.form).then(
              (res) => {
                this.$notify({
                  title: "新增派公司機成功",
                  message: res.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-check-2",
                  type: "success",
                });
              },
              (res) => {
                this.$notify({
                  title: "新增派公司機失敗",
                  message: res.response.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-alert-circle-i",
                  type: "danger",
                });
              }
            );
          }
          if (this.dialogStatus === "edit") {
            axios.put(`/soil/car`, this.form).then(
              (res) => {
                this.$notify({
                  title: "編輯派公司機成功",
                  message: res.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-check-2",
                  type: "success",
                });
              },
              (res) => {
                this.$notify({
                  title: "編輯派公司機失敗",
                  message: res.response.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-alert-circle-i",
                  type: "danger",
                });
              }
            );
          }
          this.$emit("close-dialog", {
            visible: !this.visible,
            data: this.form,
          });
        }
      });
    },
    handleClear(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = this.form;
          form.user_name = null;
          
          if (this.dialogStatus === "add") {
            axios.post(`/soil/car/${this.soilDispatchListId}`, this.form).then(
              (res) => {
                this.$notify({
                  title: "新增派公司機成功",
                  message: res.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-check-2",
                  type: "success",
                });
              },
              (res) => {
                this.$notify({
                  title: "新增派公司機失敗",
                  message: res.response.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-alert-circle-i",
                  type: "danger",
                });
              }
            );
          }
          if (this.dialogStatus === "edit") {
            axios.put(`/soil/car`, this.form).then(
              (res) => {
                this.$notify({
                  title: "編輯派公司機成功",
                  message: res.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-check-2",
                  type: "success",
                });
              },
              (res) => {
                this.$notify({
                  title: "編輯派公司機失敗",
                  message: res.response.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-alert-circle-i",
                  type: "danger",
                });
              }
            );
          }
          this.$emit("close-dialog", {
            visible: !this.visible,
            data: this.form,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
