<template>
    <div class="row">
        <div class="col-md-12">
            <h4 class="title">施工內容</h4>
            <card>
                <!-- <h4 slot="header" class="card-title"></h4> -->

                <el-button style="margin-left: 10px" size="small" type="success" @click="handleAddLocation()">新增施工內容
                </el-button>
            </card>
            <!-- end card -->
        </div>
        <div class="col-md-12">
            <h4 class="title">施工內容列表</h4>
        </div>
        <div class="col-md-12 card">
            <div class="card-header"></div>
            <div class="card-body row">
                <div class="col-sm-12 mt-2">
                    <el-table v-loading="loading" class="table-striped" :data="tableData" border style="width: 100%">
                        <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
                            :prop="column.prop" :label="column.label" :formatter="column.formatter">
                        </el-table-column>

                        <el-table-column :min-width="140" fixed="right" class-name="td-actions" label="功能">
                            <template slot-scope="props">
                                <p-button type="info" size="sm" icon title="人員資訊"
                                    @click="handleInfo(props.$index, props.row)">
                                    <i class="fa fa-user"></i>
                                </p-button>
                                <p-button type="success" size="sm" icon title="人員編輯"
                                    @click="handleEdit(props.$index, props.row)">
                                    <i class="fa fa-edit"></i>
                                </p-button>
                                <p-button type="danger" size="sm" icon title="人員刪除"
                                    @click="handleDelete(props.$index, props.row)">
                                    <i class="fa fa-times"></i>
                                </p-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="col-sm-6 pagination-info">
                    <p class="category">
                        Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                    </p>
                </div>
                <div class="col-sm-6">
                    <p-pagination class="pull-right" v-model="pagination.currentPage" @change-page="handleCurrentChange"
                        :per-page="pagination.perPage" @per-page="handleSizeChange" :total="pagination.total">
                    </p-pagination>
                </div>
            </div>
        </div>

        <div v-if="dialogVisible">
            <typeDialog :visible="dialogVisible" @close-dialog="handleCloseDialog" :current-data="currentData"
                :dialog-status="dialogStatus" />
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import swal from "sweetalert2";
import moment from "moment";
import { Table, TableColumn, Select, Option, Tag, Loading, Button } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import axios from "axios";
import typeDialog from "./TypeDialog.vue";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Loading);
Vue.use(Button);
export default {
    components: {
        PPagination,
        [Tag.name]: Tag,
        typeDialog,
    },
    computed: {
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
    },
    data() {
        return {
            pagination: {
                perPage: 100,
                currentPage: 1,
                total: 0,
            },
            tableColumns: [
                {
                    prop: "name",
                    label: "名稱",
                },
                {
                    prop: "updatedAt",
                    label: "更新時間",
                    formatter(row, column, cellValue, index) {
                        return moment(cellValue).local().format("YYYY-MM-DD HH:mm:ss");
                    },
                },
            ],
            tableData: [],
            dialogVisible: false,
            currentData: {},
            dialogStatus: "info",
            loading: true,
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            const params = {};
            params.limit = this.pagination.perPage;
            params.index = this.pagination.currentPage;
            params.q = this.searchQuery ? this.searchQuery : undefined;

            this.loading = true;
            axios.get(`/soil/type`, { params }).then((res) => {
                this.tableData = res.data.rows;
                this.pagination.total = res.data.count;

                this.loading = false;
            });
        },
        handleSizeChange(size) {
            this.pagination.perPage = size;
            this.getData();
        },
        handleCurrentChange(currentPage) {
            this.pagination.currentPage = currentPage;
            this.getData();
        },
        handleAddLocation() {
            this.currentData = {

            };
            this.dialogVisible = true;
            this.dialogStatus = "add";

            // alert(`Your want to like ${row.name}`);
        },
        handleInfo(index, row) {
            this.currentData = row;
            this.dialogVisible = true;
            this.dialogStatus = "info";
        },
        handleEdit(index, row) {
            this.currentData = row;
            this.dialogVisible = true;
            this.dialogStatus = "edit";
            // alert(`Your want to edit ${row.name}`);
        },
        handleCloseDialog({ visible }) {
            // this.data.find({ user_id } => user_id === data.user_id)
            this.dialogVisible = visible;
            this.timeOutReload = setTimeout(() => {
                this.getData();
            }, 500);
        },
        handleDelete(index, row) {
            swal
                .fire({
                    title: "是否移除",
                    showDenyButton: true,
                    confirmButtonText: "是",
                    denyButtonText: "否",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios
                            .delete(`/soil/type/${row.id}`)
                            .then(
                                (res) => {
                                    this.$notify({
                                        title: "移除施工內容成功",
                                        message: res.data.msg,
                                        verticalAlign: "bottom",
                                        icon: "nc-icon nc-check-2",
                                        type: "success",
                                    });
                                    swal.fire("移除施工內容成功!", "", "success");
                                    this.timeOutReload = setTimeout(() => {
                                        this.getData();
                                    }, 500);
                                },
                                (res) => {
                                    this.$notify({
                                        title: "移除施工內容失敗",
                                        message: res.response.data.msg,
                                        verticalAlign: "bottom",
                                        icon: "nc-icon nc-alert-circle-i",
                                        type: "danger",
                                    });
                                    swal.fire("移除施工內容失敗", "", "error");
                                }
                            );
                    } else if (result.isDenied) {
                        swal.fire("施工內容尚未移除", "", "info");
                    }
                });
        },
    },
};
</script>
<style lang="scss">
.el-table .td-actions {
    button.btn {
        margin-right: 5px;
    }
}
</style>
