<template>
  <div class="row">
    <el-dialog title="施工內容" :visible.sync="visible" :before-close="handleClose">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="施工內容" prop="name">
          <el-input v-model="form.name" :disabled="dialogStatus === 'info' ? true : false"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('form')">確 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import QrcodeVue from "qrcode.vue";
import {
  Dialog,
  Button,
  Form,
  FormItem,
  Input,
  Checkbox,
  CheckboxGroup,
  Upload,
  TimeSelect,
  DatePicker,
  Col,
} from "element-ui";
import { Card } from "src/components/UIComponents";
import axios from "axios";
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Upload);
Vue.use(TimeSelect);
Vue.use(DatePicker);
Vue.use(Col);

export default {
  components: {
    Card,
    QrcodeVue,
  },
  name: "soil-type-dialog",
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
    currentData: {
      type: Object,
      require: true,
      default: {},
    },
    dialogStatus: {
      type: String,
      require: true,
      defalut: "info",
    },
  },
  data() {
    return {
      form: {

      },
      rules: {
        name: [{ required: true, message: "請輸入施工內容", trigger: "blur" }],
      },
    };
  },
  created() {
    this.form = { ...this.currentData };

    console.log(this.form);
  },
  methods: {
    handleClose() {
      this.$emit("close-dialog", { visible: !this.visible });
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogStatus === "add") {
            axios
              .post(`/soil/type`, {
                name: this.form.name,
              })
              .then(
                (res) => {
                  this.$notify({
                    title: "新增施工內容成功",
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                },
                (res) => {
                  this.$notify({
                    title: "新增施工內容失敗",
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                }
              );
          }
          if (this.dialogStatus === "edit") {
            axios
              .put(`/soil/type/${this.form.id}`, {
                name: this.form.name,
              })
              .then(
                (res) => {
                  this.$notify({
                    title: "編輯施工內容成功",
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                },
                (res) => {
                  this.$notify({
                    title: "編輯施工內容失敗",
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                }
              );
          }
          this.$emit("close-dialog", {
            visible: !this.visible,
            data: this.form,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
