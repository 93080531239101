<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">陌生人辨識功能</h4>
      <card>
        <!-- <h4 slot="header" class="card-title"></h4> -->

        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="handleAllStrangerDelete()"
          >移除所有陌生人紀錄</el-button
        >
        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="exportStrangerFile"
          >匯出</el-button
        >
      </card>
      <!-- end card -->
    </div>
    <div class="col-md-12">
      <h4 class="title">陌生人辨識紀錄</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category"></div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select
            class="select-default"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <!-- <fg-input
              class="input-sm"
              placeholder="Search"
              v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split"
            >
            </fg-input> -->
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            v-loading="loading"
            class="table-striped"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>
            <el-table-column min-width="55" prop="photo" label="照片">
              <template slot-scope="scope">
                <img :src="scope.row.photo" />
              </template>
            </el-table-column>

            <el-table-column
              :min-width="40"
              fixed="right"
              class-name="td-actions"
              label="功能"
            >
              <template slot-scope="props">
                <p-button
                  type="info"
                  size="sm"
                  title="事件建檔"
                  icon
                  @click="handleEventCreate(props.$index, props.row)"
                >
                  <i class="fa fa-user"></i>
                </p-button>
                <p-button
                  type="info"
                  size="sm"
                  title="單日建檔"
                  icon
                  @click="handleEventOneDayCreate(props.$index, props.row)"
                >
                  <i class="nc-icon nc-lock-circle-open"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <div class="col-sm-6">
          <p-pagination
            class="pull-right"
            v-model="pagination.currentPage"
            @change-page="handleCurrentChange"
            :per-page="pagination.perPage"
            @per-page="handleSizeChange"
            :total="pagination.total"
          >
          </p-pagination>
        </div>
      </div>

      <div v-if="dialogVisible">
        <strangerDialog
          :visible="dialogVisible"
          @close-dialog="handleCloseDialog"
          :current-data="currentData"
          :dialog-status="dialogStatus"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import swal from "sweetalert2";
import moment from "moment";
import { Table, TableColumn, Select, Option, Tag, Loading } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import axios from "axios";
import strangerDialog from "./StrangerDialog.vue";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Loading);
export default {
  components: {
    PPagination,
    [Tag.name]: Tag,
    strangerDialog,
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      tableColumns: [
        {
          prop: "id",
          label: "序號",
          minWidth: 30,
        },
        {
          prop: "body_temperature",
          label: "體溫",
          minWidth: 50,
        },
        {
          prop: "room_temperature",
          label: "室內溫度",
          minWidth: 50,
        },
        {
          prop: "sn",
          label: "裝置",
          minWidth: 50,
        },
        {
          prop: "recog_time",
          label: "辨識時間",
          minWidth: 80,
        },
      ],
      tableData: [],
      dialogVisible: false,
      currentData: {},
      dialogStatus: "info",
      loading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const limit = this.pagination.perPage;
      const index = this.pagination.currentPage;

      this.loading = true;
      axios
        .get(`/face/user/stranger?limit=${limit}&index=${index}`)
        .then((res) => {
          this.tableData = res.data.rows;
          this.pagination.total = res.data.count;
          this.loading = false;
        });
    },
    handleSizeChange(size) {
      this.pagination.perPage = size;
      this.getData();
    },
    handleCurrentChange(currentPage) {
      this.pagination.currentPage = currentPage;
      this.getData();
    },
    handleAllStrangerDelete() {
      swal
        .fire({
          title: "是否移除",
          text: "請注意！移除所有辨識紀錄會，同步移除陌生人實名制紀錄照片！",
          showDenyButton: true,
          confirmButtonText: "是",
          denyButtonText: "否",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios.delete(`/face/user/stranger`).then(
              (res) => {
                this.$notify({
                  title: "移除所有陌生人紀錄成功",
                  message: res.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-check-2",
                  type: "success",
                });
                swal.fire("移除所有陌生人紀錄成功!", "", "success");
                this.timeOutReload = setTimeout(() => {
                  this.getData();
                }, 500);
              },
              (res) => {
                this.$notify({
                  title: "移除所有陌生人紀錄失敗",
                  message: res.response.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-alert-circle-i",
                  type: "danger",
                });
                swal.fire("移除所有陌生人紀錄失敗", "", "error");
              }
            );
          } else if (result.isDenied) {
            swal.fire("所有陌生人紀錄尚未移除", "", "info");
          }
        });
    },
    handleEventCreate(index, row) {
      this.currentData = {
        valid_cycle: [],
        devices: [],
        user_id: null,
        name: null,
        nickname: null,
        face_image: row.photo,
        ic: null,
        password: null,
        user_type: 0,
        phone: null,
        id_valid: null,
        group: [],
      };
      this.dialogVisible = true;
      this.dialogStatus = "add";
    },
    handleEventOneDayCreate(index, row) {
      this.currentData = {
        valid_cycle: [],
        devices: [],
        user_id: null,
        name: null,
        nickname: null,
        face_image: row.photo,
        ic: null,
        password: null,
        user_type: 0,
        phone: null,
        id_valid: moment().add(1, "days").format("YYYY-MM-DD"),
        group: [],
      };
      this.dialogVisible = true;
      this.dialogStatus = "add";
    },
    handleCloseDialog({ visible }) {
      this.dialogVisible = visible;
    },
    async exportStrangerFile() {
      const params = {};
      params.encode = "big5";
      swal.fire("匯出陌生人辨識紀錄檔案中 請稍候 ...", "", "success");
      let fileData = await axios({
        url: "/face/user/stranger/export",
        responseType: "blob",
        params,
      });
      let URL = window.URL || window.webkitURL;
      let downloadUrl = URL.createObjectURL(fileData.data);
      let a = document.createElement("a");
      a.download = "匯出陌生人辨識紀錄檔案.zip";
      a.href = downloadUrl;
      a.click();
      a = null;
      downloadUrl && URL.revokeObjectURL(downloadUrl);
    },
  },
};
</script>
<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
</style>
