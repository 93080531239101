<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">匯出當月土方</h4>
      <div class="col-md-12 card">
        <div class="card-body">
          <div class="col-sm-12">
            <el-date-picker
              v-model="date"
              align="right"
              type="date"
              placeholder="選擇日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>

            <el-button
              style="margin-left: 10px"
              size="small"
              type="success"
              @click="exportUserFile"
              >匯出當月土方</el-button
            >
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!--  end col-md-6  -->
    <div class="col-md-12">
      <h4 class="title">匯入 VOLVO</h4>
      <card>
        <!-- <h4 slot="header" class="card-title"></h4> -->
        <el-form :model="ruleForm" ref="ruleForm">
          <el-form-item label="匯入檔案" prop="import_file">
            <el-upload
              accept=".zip"
              ref="upload"
              name="import_file"
              action=""
              :file-list="fileList"
              :limit="1"
              :on-exceed="handleExceed"
              :on-change="handleUploadChange"
              :before-upload="handleBeforeUpload"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :auto-upload="false"
            >
              <el-button slot="trigger" size="small" type="primary"
                >選取檔案</el-button
              >
              <el-button
                style="margin-left: 10px"
                size="small"
                type="success"
                @click="submitUpload('ruleForm')"
                >上傳</el-button
              >
              <el-progress :percentage="progressPercent"></el-progress>
            </el-upload>
          </el-form-item>
        </el-form>
      </card>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import swal from "sweetalert2";
import {
  Upload,
  Button,
  Checkbox,
  CheckboxGroup,
  Form,
  FormItem,
  Progress,
  DatePicker,
} from "element-ui";
import { Card } from "src/components/UIComponents";
import axios from "axios";

Vue.use(Upload);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Progress);
Vue.use(DatePicker);

export default {
  components: {
    Card,
  },
  data() {
    return {
      date: new Date().toISOString().split("T")[0],
      ruleForm: {
        devices: [],
      },
      fileList: [],
      progressPercent: 0,
      devices: [],
      deviceCheckAll: false,
      isDeviceIndeterminate: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    async exportUserFile() {
      swal.fire("匯出土方 請稍候 ...", "", "success");
      const params = {};
      params.encode = "big5";
      params.date = this.date
      
      let fileData = await axios({
        url: `/earthmovingRouter/report`,
        responseType: "blob",
        params,
      });
      let URL = window.URL || window.webkitURL;
      let downloadUrl = URL.createObjectURL(fileData.data);
      let a = document.createElement("a");
      a.download = `${this.date}.zip`;
      a.href = downloadUrl;
      a.click();
      a = null;
      downloadUrl && URL.revokeObjectURL(downloadUrl);
    },

    handleUploadChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    handleBeforeUpload(file) {
      this.fileList.pop(file);
    },
    submitUpload(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          this.fileList.forEach(function (file) {
            fd.append("import_file", file.raw, file.name);
          });

          axios
            .post(`/volvo/haul/upload`, fd, {
              onUploadProgress: (progressEvent) => {
                this.progressPercent = Number(
                  ((progressEvent.loaded / progressEvent.total) * 100).toFixed(
                    2
                  )
                );
              },
            })
            .then(
              (res) => {
                this.$notify({
                  title: "匯入成功",
                  message: res.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-check-2",
                  type: "success",
                });
              },
              (res) => {
                this.$notify({
                  title: "匯入失敗",
                  message: res.response.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-alert-circle-i",
                  type: "danger",
                });
              }
            );
        } else {
          return false;
        }
      });
    },
    handleExceed(files, fileList) {
      alert("請移除已上傳檔案");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script>
<style></style>
