<template>
  <div>
    <div class="col-md-12">
      <h4 class="title">派工司機列表 - 晚班加班</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-body row">
        <div v-for="(car_user, index) in this.tableData" :key="index" class="col-2 my-2">
          <el-button :type="car_user.user_name ? 'primary' : ''" @click="handleAddCarUser(car_user)" plain>{{ car_user.sn }} - {{car_user.user_name}}</el-button>
        </div>
      </div>
    </div>

    <div v-if="dialogVisible">
      <soilCarDialog :visible="dialogVisible" @close-dialog="handleCloseDialog" :current-all="currentAll"
        :current-data="currentData" :dialog-status="dialogStatus" />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import swal from "sweetalert2";
import moment from "moment";
import { Table, TableColumn, Select, Option, Tag, Loading } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import axios from "axios";
import soilCarDialog from "./SoilCarUserDialog";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Loading);
export default {
  props: ['carUserNightOvertime'],
  components: {
    PPagination,
    [Tag.name]: Tag,
    soilCarDialog,
  },
  name: "soil-car-user-night-overtime",
  computed: {

  },
  props: {
    id: {
      type: String,
    }
  },
  data() {
    return {
      pagination: {},
      tableColumns: [
        {
          prop: "work_day",
          label: "班表",
          formatter(row, column, cellValue, index) {
            if (cellValue === 0) {
              return '早班加班(6:30)'
            }
            if (cellValue === 1) {
              return '早班(7:00)'
            }
            if (cellValue === 2) {
              return '晚班加班(19:00)'
            }
          },
        },
        {
          prop: "sn",
          label: "車牌號碼",
        },
        {
          prop: "user_name",
          label: "司機",
        },
        {
          prop: "updateAt",
          label: "更新時間",
          formatter(row, column, cellValue, index) {
            return moment(cellValue).local().format("YYYY-MM-DD HH:mm:ss");
          },
        },
      ],
      tableData: [],
      dialogVisible: false,
      currentAll: [],
      currentData: {},
      date: "",
      dialogStatus: "info",
      loading: true,
    };
  },
  mounted() {
    this.getData();
    this.getDate();
  },
  watch: {

  },
  methods: {
    async getDate() {
      const params = {};
      try {
        let res = await axios.get(`/soil/dispatch?id=${this.id}`, { params });
        this.date = res.data.rows[0].date;

      } catch (error) {
        console.log(error)
      }
    },
    getData() {
      const params = {};

      this.loading = true;
      axios.get(`/soil/car/${this.id}/2`, { params }).then((res) => {
        this.tableData = res.data.rows;
        this.pagination.total = res.data.count;
        this.loading = false;

        this.currentAll = res.data.rows;
      });
    },
    handleAddCarUser(info) {
      const date = this.date

      this.currentData = {
        sn: info.sn,
        user_id: info.user_id,
        user_name: info.user_name,
        work_day: 2,
        date
      };
      this.dialogVisible = true;
      // this.dialogStatus = "add";
      this.dialogStatus = "edit";
    },
    handleInfo(index, row) {
      this.currentIndex = index;
      this.currentData = row;
      this.dialogVisible = true;
      this.dialogStatus = "info";
    },
    handleEdit(index, row) {
      this.currentIndex = index;
      this.currentData = row;
      this.dialogVisible = true;
      this.dialogStatus = "edit";
    },
    handleCloseDialog({ visible }) {
      this.dialogVisible = visible;
      this.timeOutReload = setTimeout(() => {
        this.getData();
      }, 500);
    },
    handleDelete(index, row) {
      swal
        .fire({
          title: "是否移除",
          showDenyButton: true,
          confirmButtonText: "是",
          denyButtonText: "否",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`/soil/car/${row.id}`)
              .then(
                (res) => {
                  this.$notify({
                    title: "移除派工司機成功",
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                  swal.fire("移除派工司機成功!", "", "success");
                  this.timeOutReload = setTimeout(() => {
                    this.getData();
                  }, 500);
                },
                (res) => {
                  this.$notify({
                    title: "移除派工司機失敗",
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                  swal.fire("移除派工司機失敗", "", "error");
                }
              );
          } else if (result.isDenied) {
            swal.fire("派工司機尚未移除", "", "info");
          }
        });
    },
    handleRefresh() {
      this.timeOutReload = setTimeout(() => {
        this.getData();
      }, 500);
    }
  },
};
</script>
<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
</style>
