<template>
  <div class="row">
    <el-dialog title="排班系統" :visible.sync="visible" :before-close="handleClose">
      <el-form ref="form" :model="form" :rules="rules">

        <el-form-item label="班表" required>
          <el-select placeholder="班表" v-model="form.work_day" :disabled="true">
            <el-option v-for="item in workDayOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="施工區域" required>
          <el-select placeholder="施工區域" v-model="form.start_area" :disabled="dialogStatus === 'info' ? true : false">
            <el-option v-for="item in startArea" :key="item.name" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="施工內容" required>
          <el-select placeholder="施工內容" v-model="form.type" :disabled="dialogStatus === 'info' ? true : false">
            <el-option v-for="item in type" :key="item.name" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="施工機具" required>
          <el-select v-model="form.start_car_user_list" :disabled="dialogStatus === 'info' ? true : false"
            value-key="id" multiple placeholder="施工機具" @change="carSel($event)" @remove-tag="carUnSel($event)">
            <el-option v-for="item in cars" :key="item.id"
              :label="item.work_day_value + ' - ' + item.sn + ' - ' + item.user_name" :value="item"
              :disabled="item.disabled">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="施工車輛">
          <el-select v-model="form.end_car_user_vehicle_list" :disabled="dialogStatus === 'info' ? true : false"
            value-key="id" multiple placeholder="施工車輛" @change="carSel($event)" @remove-tag="carUnSel($event)">
            <el-option v-for="item in cars" :key="item.id"
              :label="item.work_day_value + ' - ' + item.sn + ' - ' + item.user_name" :value="item"
              :disabled="item.disabled">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="土尾區域">
          <el-select placeholder="土尾區域" v-model="form.end_area" :disabled="dialogStatus === 'info' ? true : false">
            <el-option v-for="item in endArea" :key="item.name" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="土尾車輛">
          <el-select v-model="form.end_car_user_list" :disabled="dialogStatus === 'info' ? true : false" value-key="id"
            multiple placeholder="土尾車輛" @change="carSel($event)" @remove-tag="carUnSel($event)">
            <el-option v-for="item in endCars" :key="item.id"
              :label="item.work_day_value + ' - ' + item.sn + ' - ' + item.user_name" :value="item"
              :disabled="item.disabled">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('form')">確 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Dialog,
  Button,
  Form,
  FormItem,
  Input,
  Select
} from "element-ui";
import { Card } from "src/components/UIComponents";
import axios from "axios";
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select)

export default {
  components: {
    Card,
  },
  name: "soil-dispatch-dialog",
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
    currentIndex: {
      type: Number,
      require: true,
      default: 0,
    },
    currentData: {
      type: Object,
      require: true,
      default: {},
    },
    dialogStatus: {
      type: String,
      require: true,
      defalut: "info",
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      // id: 1,
      form: {

      },
      rules: {},
      startArea: [],
      endArea: [],
      devices: [],
      group: [],
      type: [],
      cars: [],
      endCars: [],

      workDayOptions: [{
        value: 0,
        label: "早班加班(6:30)"
      }, {
        value: 1,
        label: "早班(7:00)"
      }, {
        value: 2,
        label: "晚班加班(19:00)"
      }],
    };
  },
  created() {
    this.getData(this.currentData.id);
    this.form = { ...this.currentData };
    this.getStartArea();
    this.getEndArea();
    this.getType();
    this.getCar();
    // console.log(this.form);
  },
  methods: {
    getData(id) {

    },
    getStartArea() {
      axios.get(`/location/area?area=3`).then((res) => {
        this.startArea = res.data.rows;
      });
    },
    getEndArea() {
      axios.get(`/location/area?area=2`).then((res) => {
        this.endArea = res.data.rows;
      });
    },
    getType() {
      axios.get(`/soil/type`).then((res) => {
        this.type = res.data.rows;
      });
    },
    async getDispatchDetail() {
      let res = await axios.get(`/soil/dispatch/detail/${this.id}/${this.currentData.work_day}`);

      return res.data.rows
    },
    async getCar() {
      const dispatchDetails = await this.getDispatchDetail()

      axios.get(`/soil/car/${this.id}/${this.currentData.work_day}?not_null=true`).then((res) => {
        this.cars = res.data.rows;
        this.endCars = JSON.parse(JSON.stringify(res.data.rows))

        for (let index = 0; index < this.cars.length; index++) {
          const car = this.cars[index]

          for (let index = 0; index < dispatchDetails.length; index++) {
            const dispatchDetail = dispatchDetails[index]
            for (let index = 0; index < dispatchDetail.start_car_user_list.length; index++) {
              const start_car_user_list = dispatchDetail.start_car_user_list[index]
              if (car.sn === start_car_user_list.sn) { car.disabled = true; }
            }
            for (let index = 0; index < dispatchDetail.end_car_user_vehicle_list.length; index++) {
              const end_car_user_vehicle_list = dispatchDetail.end_car_user_vehicle_list[index]
              if (car.sn === end_car_user_vehicle_list.sn) { car.disabled = true; }
            }
          }
        }

      });
    },
    carSel(selVals) {
      for (let index = 0; index < this.cars.length; index++) {
        const car = this.cars[index]

        for (let index = 0; index < selVals.length; index++) {
          const selVal = selVals[index]
          if (car.sn === selVal.sn) { car.disabled = true; }
        }
      }
    },
    carUnSel(unSelVal) {
      for (let index = 0; index < this.cars.length; index++) {
        const car = this.cars[index]

        if (car.sn === unSelVal.sn) { car.disabled = false; }
      }
    },
    handleClose() {
      this.$emit("close-dialog", { visible: !this.visible });
    },
    handleSubmit(formName) {
      const id = this.id
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogStatus === "add") {
            axios
              .post(`/soil/dispatch/detail/${id}/${this.form.work_day}`, this.form)
              .then(
                (res) => {
                  this.$notify({
                    title: "新增派工資訊成功",
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                },
                (res) => {
                  this.$notify({
                    title: "新增派工資訊失敗",
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                }
              );
          }
          if (this.dialogStatus === "edit") {
            axios
              .put(`/soil/dispatch/detail/${this.form.id}`, this.form)
              .then(
                (res) => {
                  this.$notify({
                    title: "編輯派工資訊成功",
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                },
                (res) => {
                  this.$notify({
                    title: "編輯派工資訊失敗",
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                }
              );
          }
          this.$emit("close-dialog", {
            visible: !this.visible,
            data: this.form,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
