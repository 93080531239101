<template>
  <div class="row">
    <el-dialog title="座標" :visible.sync="visible" :before-close="handleClose">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" :disabled="dialogStatus === 'info' ? true : false"></el-input>
        </el-form-item>
        <el-form-item label="座標" prop="location">
          <el-input type="textarea" v-model="form.location" :disabled="dialogStatus === 'info' ? true : false">
          </el-input>
        </el-form-item>
        <el-form-item label="區域" prop="area">
          <el-select v-model="form.area" placeholder="選擇" :disabled="dialogStatus === 'info' ? true : false">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('form')">確 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import QrcodeVue from "qrcode.vue";
import {
  Dialog,
  Button,
  Form,
  FormItem,
  Input,
  Checkbox,
  CheckboxGroup,
  Upload,
  TimeSelect,
  DatePicker,
  Col,
} from "element-ui";
import { Card } from "src/components/UIComponents";
import axios from "axios";
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Upload);
Vue.use(TimeSelect);
Vue.use(DatePicker);
Vue.use(Col);

export default {
  components: {
    Card,
    QrcodeVue,
  },
  name: "location-dialog",
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
    currentData: {
      type: Object,
      require: true,
      default: {},
    },
    dialogStatus: {
      type: String,
      require: true,
      defalut: "info",
    },
  },
  data() {
    return {
      form: {

      },
      rules: {
        name: [{ required: true, message: "請輸入姓名", trigger: "blur" }],
        // location: [{ required: true, message: "請輸入座標", trigger: "blur" }],
        area: [{ required: true, message: "請輸入選擇區域", trigger: "blur" }],
      },
      options: [{
        value: 0,
        label: '全區'
      }, {
        value: 1,
        label: '分區地圖'
      }, {
        value: 2,
        label: '土尾區'
      }, {
        value: 3,
        label: '施工區域'
      }],

    };
  },
  created() {
    this.form = { ...this.currentData };

    console.log(this.form);
  },
  methods: {
    handleClose() {
      this.$emit("close-dialog", { visible: !this.visible });
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogStatus === "add") {
            axios
              .post(`/location/area`, {
                name: this.form.name,
                location: JSON.parse(this.form.location),
                area: this.form.area,
              })
              .then(
                (res) => {
                  this.$notify({
                    title: "新增地標成功",
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                },
                (res) => {
                  this.$notify({
                    title: "新增地標失敗",
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                }
              );
          }
          if (this.dialogStatus === "edit") {
            axios
              .put(`/location/area/${this.form.id}`, {
                name: this.form.name,
                location: JSON.parse(this.form.location),
                area: this.form.area,
              })
              .then(
                (res) => {
                  this.$notify({
                    title: "編輯地標成功",
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                },
                (res) => {
                  this.$notify({
                    title: "編輯地標失敗",
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                }
              );
          }
          this.$emit("close-dialog", {
            visible: !this.visible,
            data: this.form,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
