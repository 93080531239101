<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">人臉辨識使用者功能</h4>
      <card>
        <!-- <h4 slot="header" class="card-title"></h4> -->

        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="handleAddUser()"
          >新增使用者</el-button
        >
        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="handleDeleteAllUser()"
          >移除所有使用者</el-button
        >
      </card>
      <!-- end card -->
    </div>
    <div class="col-md-12">
      <h4 class="title">工時功能</h4>
      <card>
        <el-date-picker
          v-model="date"
          align="right"
          type="date"
          placeholder="選擇日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>

        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="workFileDownload"
          >工時下載</el-button
        >
      </card>
      <!-- end card -->
    </div>
    <div class="col-md-12">
      <h4 class="title">人臉辨識使用者列表</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-header"></div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select
            class="select-default"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input
              class="input-sm"
              placeholder="Search"
              v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split"
            >
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            v-loading="loading"
            class="table-striped"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              :formatter="column.formatter"
            >
            </el-table-column>
            <el-table-column prop="group" label="群組">
              <template slot-scope="scope">
                <el-tag :key="gp" v-for="gp in scope.row.group" type="primary">
                  {{ gp }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="photo" label="照片">
              <template slot-scope="scope">
                <img :src="scope.row.face_image" />
              </template>
            </el-table-column>
            <el-table-column prop="devices" label="裝置">
              <template slot-scope="scope">
                <el-tag
                  :key="device"
                  v-for="device in scope.row.devices"
                  type="primary"
                  size="mini"
                >
                  {{ device }}
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column
              :min-width="140"
              fixed="right"
              class-name="td-actions"
              label="功能"
            >
              <template slot-scope="props">
                <p-button
                  type="info"
                  size="sm"
                  icon
                  title="人員資訊"
                  @click="handleInfo(props.$index, props.row)"
                >
                  <i class="fa fa-user"></i>
                </p-button>
                <p-button
                  type="success"
                  size="sm"
                  icon
                  title="人員編輯"
                  @click="handleEdit(props.$index, props.row)"
                >
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button
                  type="danger"
                  size="sm"
                  icon
                  title="人員刪除"
                  @click="handleDelete(props.$index, props.row)"
                >
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <div class="col-sm-6">
          <p-pagination
            class="pull-right"
            v-model="pagination.currentPage"
            @change-page="handleCurrentChange"
            :per-page="pagination.perPage"
            @per-page="handleSizeChange"
            :total="pagination.total"
          >
          </p-pagination>
        </div>
      </div>
    </div>

    <div v-if="dialogVisible">
      <userDialog
        :visible="dialogVisible"
        @close-dialog="handleCloseDialog"
        :current-data="currentData"
        :dialog-status="dialogStatus"
      />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import swal from "sweetalert2";
import moment from "moment";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Tag,
  Loading,
  DatePicker,
} from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import axios from "axios";
import userDialog from "./UserDialog.vue";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Loading);
Vue.use(DatePicker);
export default {
  components: {
    PPagination,
    [Tag.name]: Tag,
    userDialog,
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  data() {
    return {
      date: new Date().toISOString().split("T")[0],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 200],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["user_name", "email", "age"],
      tableColumns: [
        {
          prop: "id",
          label: "序號",
        },
        {
          prop: "user_id",
          label: "使用者 ID",
        },
        {
          prop: "name",
          label: "姓名",
        },
        {
          prop: "ic",
          label: "卡片 ID",
        },
        {
          prop: "createdAt",
          label: "建立時間",
          formatter(row, column, cellValue, index) {
            return moment(cellValue).local().format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          prop: "updatedAt",
          label: "更新時間",
          formatter(row, column, cellValue, index) {
            return moment(cellValue).local().format("YYYY-MM-DD HH:mm:ss");
          },
        },
      ],
      tableData: [],
      dialogVisible: false,
      currentData: {},
      dialogStatus: "info",
      loading: true,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    searchQuery: function () {
      this.getData();
    },
  },
  methods: {
    getData() {
      const params = {};
      params.limit = this.pagination.perPage;
      params.index = this.pagination.currentPage;
      params.q = this.searchQuery ? this.searchQuery : undefined;

      this.loading = true;
      axios.get(`/face/user`, { params }).then((res) => {
        this.tableData = res.data.rows;
        this.pagination.total = res.data.count;
        this.loading = false;
      });
    },
    handleSizeChange(size) {
      this.pagination.perPage = size;
      this.getData();
    },
    handleCurrentChange(currentPage) {
      this.pagination.currentPage = currentPage;
      this.getData();
    },
    handleAddUser() {
      this.currentData = {
        valid_cycle: [],
        devices: [],
        user_id: null,
        name: null,
        nickname: null,
        face_image: null,
        ic: null,
        password: null,
        user_type: 0,
        phone: null,
        id_valid: null,
        group: [],
      };
      this.dialogVisible = true;
      this.dialogStatus = "add";

      // alert(`Your want to like ${row.name}`);
    },
    handleInfo(index, row) {
      this.currentData = row;
      this.dialogVisible = true;
      this.dialogStatus = "info";
    },
    handleEdit(index, row) {
      this.currentData = row;
      this.dialogVisible = true;
      this.dialogStatus = "edit";
      // alert(`Your want to edit ${row.name}`);
    },
    handleCloseDialog({ visible }) {
      // this.data.find({ user_id } => user_id === data.user_id)
      this.dialogVisible = visible;
      this.timeOutReload = setTimeout(() => {
        this.getData();
      }, 500);
    },
    handleDelete(index, row) {
      swal
        .fire({
          title: "是否移除",
          showDenyButton: true,
          confirmButtonText: "是",
          denyButtonText: "否",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`/face/user`, {
                data: {
                  sn: row.devices,
                  data: {
                    user_id: row.user_id,
                    user_type: row.user_type,
                  },
                },
              })
              .then(
                (res) => {
                  this.$notify({
                    title: "移除人臉辨識使用者成功",
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                  swal.fire("移除人臉辨識使用者成功!", "", "success");
                  this.timeOutReload = setTimeout(() => {
                    this.getData();
                  }, 500);
                },
                (res) => {
                  this.$notify({
                    title: "移除人臉辨識使用者失敗",
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                  swal.fire("移除人臉辨識使用者失敗", "", "error");
                }
              );
          } else if (result.isDenied) {
            swal.fire("人臉辨識使用者尚未移除", "", "info");
          }
        });
    },
    handleDeleteAllUser() {
      swal
        .fire({
          title: "是否移除所有使用者人臉辨識使用者",
          showDenyButton: true,
          confirmButtonText: "是",
          denyButtonText: "否",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios.delete(`/face/users`).then(
              (res) => {
                this.$notify({
                  title: "移除所有人臉辨識使用者成功",
                  message: res.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-check-2",
                  type: "success",
                });
                swal.fire("移除所有人臉辨識使用者成功!", "", "success");
                this.timeOutReload = setTimeout(() => {
                  this.getData();
                }, 500);
              },
              (res) => {
                this.$notify({
                  title: "移除所有人臉辨識使用者失敗",
                  message: res.response.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-alert-circle-i",
                  type: "danger",
                });
                swal.fire("移除所有人臉辨識使用者失敗", "", "error");
              }
            );
          } else if (result.isDenied) {
            swal.fire("人臉辨識使用者尚未移除", "", "info");
          }
        });
    },
    async workFileDownload() {
      swal.fire("匯出檔案 請稍候 ...", "", "success");
      let fileData = await axios({
        url: `/work/calc/csv?date=${this.date}`,
        responseType: "blob",
      });
      let URL = window.URL || window.webkitURL;
      let downloadUrl = URL.createObjectURL(fileData.data);
      let a = document.createElement("a");
      a.download = "工時檔案.xlsx";
      a.href = downloadUrl;
      a.click();
      a = null;
      downloadUrl && URL.revokeObjectURL(downloadUrl);
    },
  },
};
</script>
<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
</style>
