<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">派工系統 {{ this.id }}</h4>
      <card>
        <!-- <h4 slot="header" class="card-title"></h4> -->

        <el-date-picker
          v-model="tableData.date"
          align="right"
          type="date"
          placeholder="選擇日期"
          :picker-options="pickerOptions"
          @change="changeDate"
        >
        </el-date-picker>

        <el-input
          type="textarea"
          placeholder="內容"
          v-model="tableData.note"
          @change="changeDate"
        >
        </el-input>

        <el-button @click="buttonDeleteDispatch0Clicked()">清除資料</el-button>
        <el-button @click="printDayClicked()">早班派工單列印</el-button>
        <el-button @click="printDayExtraClicked()"
          >早班加班派工單列印</el-button
        >
        <el-button @click="printNightClicked()">晚班加班派工單列印</el-button>
        <el-button @click="sendDispatch">派工單傳送/存檔</el-button>
      </card>
      <!-- end card -->
    </div>

    <div class="col-md-12">
      <h4 class="title">派工單 - 早班</h4>

      <carUserTables ref="carUser" :id="this.id" v-if="render"> </carUserTables>
      <h4 class="title">新增派工單 - 早班</h4>
      <card>
        <el-button @click="buttonAddDispatch1Clicked()">新增早班派工</el-button>
      </card>
      <!-- end card -->
      <soilDispatchTables
        ref="soilDispatch"
        :id="this.id"
        v-if="render"
      ></soilDispatchTables>
    </div>

    <div class="col-md-12">
      <h4 class="title">派工單 - 早班加班</h4>

      <carUserTablesMorningOvertime
        ref="carUserMorningOvertime"
        :id="this.id"
        v-if="render"
      >
      </carUserTablesMorningOvertime>
      <h4 class="title">新增派工單 - 早班加班</h4>
      <card>
        <!-- <h4 slot="header" class="card-title"></h4> -->

        <el-button @click="buttonAddDispatch0Clicked()"
          >新增早班加班派工</el-button
        >
        <el-button @click="buttonCopyDispatch1Clicked()"
          >複製早班派工</el-button
        >
      </card>
      <!-- end card -->
      <soilDispatchTablesMorningOvertime
        ref="soilDispatchMorningOvertime"
        :id="this.id"
        v-if="render"
      ></soilDispatchTablesMorningOvertime>
    </div>

    <div class="col-md-12">
      <h4 class="title">派工單 - 晚班加班</h4>

      <carUserTablesNightOvertime
        ref="carUserNightOvertime"
        :id="this.id"
        v-if="render"
      >
      </carUserTablesNightOvertime>
      <h4 class="title">新增派工單 - 晚班加班</h4>
      <card>
        <el-button @click="buttonAddDispatch2Clicked()"
          >新增晚班加班派工</el-button
        >
        <el-button @click="buttonCopyDispatch2Clicked()"
          >複製早班派工</el-button
        >
      </card>
      <!-- end card -->
      <soilDispatchTablesNightOvertime
        ref="soilDispatchNightOvertime"
        :id="this.id"
        v-if="render"
      ></soilDispatchTablesNightOvertime>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import swal from "sweetalert2";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Tag,
  Loading,
  DatePicker,
} from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import axios from "axios";
import carUserTablesMorningOvertime from "./SoilCarUserTablesMorningOvertime.vue";
import carUserTables from "./SoilCarUserTables.vue";
import carUserTablesNightOvertime from "./SoilCarUserTablesNightOvertime.vue";
import soilDispatchTablesMorningOvertime from "./SoilDispatchTablesMorningOvertime.vue";
import soilDispatchTables from "./SoilDispatchTables.vue";
import soilDispatchTablesNightOvertime from "./SoilDispatchTablesNightOvertime.vue";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Loading);
Vue.use(DatePicker);

export default {
  components: {
    PPagination,
    [Tag.name]: Tag,
    carUserTablesMorningOvertime,
    carUserTables,
    carUserTablesNightOvertime,
    soilDispatchTablesMorningOvertime,
    soilDispatchTables,
    soilDispatchTablesNightOvertime,
  },
  computed: {},
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      render: true,
      date: new Date().toISOString().split("T")[0],
      tableData: {},
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getData();
  },
  watch: {},
  methods: {
    buttonAddDispatch0Clicked() {
      this.$refs.soilDispatchMorningOvertime.handleWorkDay0Add(this.date, 0);
    },
    buttonAddDispatch1Clicked() {
      this.$refs.soilDispatch.handleWorkDay1Add(this.date, 1);
    },
    buttonAddDispatch2Clicked() {
      this.$refs.soilDispatchNightOvertime.handleWorkDay2Add(this.date, 2);
    },
    buttonDeleteDispatch0Clicked() {
      const date = new Date().toISOString().split("T")[0];
      this.resetDispatch(date);
    },
    buttonCopyDispatch1Clicked() {
      this.copyDispatch(this.date, 1, 0);
    },
    buttonCopyDispatch2Clicked() {
      this.copyDispatch(this.date, 1, 2);
    },
    printDayClicked() {
      window.open(
        `https://soilmove-monitor.skysharp.com.tw/#/table/day/${this.id}`
      );
    },
    printDayExtraClicked() {
      window.open(
        `https://soilmove-monitor.skysharp.com.tw/#/table/dayExtra/${this.id}`
      );
    },
    printNightClicked() {
      window.open(
        `https://soilmove-monitor.skysharp.com.tw/#/table/night/${this.id}`
      );
    },
    sendDispatch() {
      swal.fire({
        title: "處理中請等待！",
        html: "派工單傳送中",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      axios.delete(`/face/queues`).then(
        (res) => {
          console.log(res.data.msg);
        },
        (res) => {
          console.error(res.response.data.msg);
        }
      );

      axios.post(`/soil/dispatch/detail/${this.id}/send`).then(
        (res) => {
          console.log(res.data.msg);
          this.$notify({
            title: "發送派工單成功",
            message: res.data.msg,
            verticalAlign: "bottom",
            icon: "nc-icon nc-check-2",
            type: "success",
          });

          swal.close();
        },
        (res) => {
          console.error(res.response.data.msg);
          this.$notify({
            title: "發送派工單失敗",
            message: res.response.data.msg,
            verticalAlign: "bottom",
            icon: "nc-icon nc-alert-circle-i",
            type: "danger",
          });

          swal.close();
        }
      );
    },
    async getData() {
      const params = {};
      try {
        let res = await axios.get(`/soil/dispatch?id=${this.id}`, { params });
        this.tableData = res.data.rows[0];
      } catch (error) {
        console.log(error);
      }
    },
    changeDate() {
      swal.fire({
        title: "處理中請等待！",
        html: "派工單傳送中",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      axios.put(`/soil/dispatch/${this.id}`, this.tableData).then(
        (res) => {
          console.log(res.data.msg);

          this.$refs.carUser.handleRefresh();
          this.$refs.carUserMorningOvertime.handleRefresh();
          this.$refs.carUserNightOvertime.handleRefresh();
          this.$refs.soilDispatch.handleRefresh();
          this.$refs.soilDispatchMorningOvertime.handleRefresh();
          this.$refs.soilDispatchNightOvertime.handleRefresh();

          swal.close();
        },
        (res) => {
          console.error(res.response.data.msg);
        }
      );
    },
    async resetDispatch(date) {
      const body = { date };

      swal.fire({
        title: "處理中請等待！",
        html: "派工單傳送中",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      try {
        let res = await axios.post(`/soil/dispatch/${this.id}/reset`, body);
        this.$refs.carUser.handleRefresh();
        this.$refs.carUserMorningOvertime.handleRefresh();
        this.$refs.carUserNightOvertime.handleRefresh();
        this.$refs.soilDispatch.handleRefresh();
        this.$refs.soilDispatchMorningOvertime.handleRefresh();
        this.$refs.soilDispatchNightOvertime.handleRefresh();

        swal.close();
      } catch (error) {
        console.log(error);
      }
    },
    async copyDispatch(date, start, end) {
      const body = { date, start, end };

      swal.fire({
        title: "處理中請等待！",
        html: "派工單傳送中",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      try {
        let res = await axios.post(
          `/soil/dispatch/detail/copy/${this.id}`,
          body
        );
        this.$refs.carUser.handleRefresh();
        this.$refs.carUserMorningOvertime.handleRefresh();
        this.$refs.carUserNightOvertime.handleRefresh();
        this.$refs.soilDispatch.handleRefresh();
        this.$refs.soilDispatchMorningOvertime.handleRefresh();
        this.$refs.soilDispatchNightOvertime.handleRefresh();

        swal.close();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
</style>
