<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-10 ml-auto mr-auto">
        <div class="card card-calendar">
          <div class="card-body">
            <fullCalendar
              ref="calendar"
              :options="calendarOptions"
              locale="zh-tw"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/zh-tw";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import Swal from "sweetalert2";
import axios from "axios";

let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      users: {},
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          center: "dayGridMonth,timeGridWeek,timeGridDay",
          right: "prev,next,today",
        },
        initialView: "dayGridMonth",
        initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        editable: true,
        selectable: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        locale: esLocale,
        events: this.handleEventSources,
      },
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      axios.get(`/face/user/name?index=1&limit=200`).then((res) => {
        const users = res.data.rows;

        for (let index = 0; index < users.length; index++) {
          const user = users[index];
          this.users[user.user_id] = user.name;
        }
      });
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleDateSelect(selectInfo) {
      console.log(selectInfo);
      // on select we show the Sweet Alert modal with an input
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "請假",
          input: "radio",
          inputOptions: this.users,
          showCancelButton: true,
          // inputValidator: (value) => {
          //   return new Promise((resolve) => {

          //   });
          // },
        })
        .then(async (result) => {
          let calendarApi = selectInfo.view.calendar;

          calendarApi.unselect(); // clear date selection
          axios
            .post(`/leave`, {
              user_id: result.value,
              name: this.users[result.value],
              start: selectInfo.start.toISOString(),
              end: selectInfo.end.toISOString(),
            })
            .then(
              (res) => {
                this.$notify({
                  title: "新增請假成功",
                  message: res.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-check-2",
                  type: "success",
                });
              },
              (res) => {
                this.$notify({
                  title: "新增請假失敗",
                  message: res.response.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-alert-circle-i",
                  type: "danger",
                });
              }
            );

          await sleep(1000);
          calendarApi.refetchEvents();
          // if (title) {
          // calendarApi.addEvent({
          //   id: createEventId(),
          //   title,
          //   start: selectInfo.startStr,
          //   end: selectInfo.endStr,
          //   allDay: selectInfo.allDay,
          // });
          // }
        });
    },
    handleEventClick(clickInfo) {
      if (confirm(`是否移除請假 '${clickInfo.event.title}'`)) {
        console.log(clickInfo);
        axios.delete(`/leave?id=${clickInfo.event.id}`).then(
          (res) => {
            this.$notify({
              title: "移除請假成功",
              message: res.data.msg,
              verticalAlign: "bottom",
              icon: "nc-icon nc-check-2",
              type: "success",
            });
          },
          (res) => {
            this.$notify({
              title: "移除請假失敗",
              message: res.response.data.msg,
              verticalAlign: "bottom",
              icon: "nc-icon nc-alert-circle-i",
              type: "danger",
            });
          }
        );
        clickInfo.event.remove();
      }
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    handleEventSources(info, successCallback, failureCallback) {
      axios.get(`/leave`, { params: info }).then(
        (res) => {
          successCallback(
            Array.prototype.slice
              .call(
                // convert to array
                res.data
              )
              .map(function (eventEl) {
                return {
                  id: eventEl.id,
                  title: eventEl.name,
                  start: eventEl.start,
                  end: eventEl.end,
                  allDay: true,
                  className: "event-red",
                };
              })
          );
        },
        (res) => {
          failureCallback(res);
        }
      );
    },
    getObjKey(obj, value) {
      return Object.keys(obj).find((key) => obj[key] === value);
    },
  },
};
</script>
<style>
#fullCalendar {
  min-height: 300px;
}
.fc-daygrid-day-events {
  display: flex;
  flex-wrap: wrap;
}
.el-loading-spinner .path {
  stroke: #66615b !important;
}

.swal2-radio {
  display: flex;
  flex-wrap: wrap;
}
.swal2-radio > label {
  flex-grow: 1;
  width: 25%;
}
</style>
