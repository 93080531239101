<template>
  <div class="row">
    <el-dialog
      title="裝置設定"
      :visible.sync="visible"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="裝置序號">
          <el-input
            v-model="form.sn"
            :disabled="dialogStatus === 'add' ? false : true"
          ></el-input>
        </el-form-item>
        <el-form-item label="裝置名稱" prop="value">
          <el-input
            v-model="form.name"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item label="廠牌" prop="value">
          <el-input
            v-model="form.brand"
            :disabled="dialogStatus === 'add' ? false : true"
          ></el-input>
        </el-form-item>
        <el-form-item label="model" prop="value">
          <el-input
            v-model="form.model"
            :disabled="dialogStatus === 'add' ? false : true"
          ></el-input>
        </el-form-item>
        <el-form-item label="版本 code">
          <el-input
            v-model="form.Device.version_code"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="版本名稱">
          <el-input
            v-model="form.Device.version_name"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="裝置編號" prop="value">
          <el-input
            v-model="form.hauler_id"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item label="派工分組">
          <el-select
            v-model="form.Device.soil_dispatch_id"
            :disabled="dialogStatus === 'info' ? true : false"
            placeholder="請選擇分組"
          >
            <el-option
              v-for="item in dispatchOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="複製所有人臉辨識使用者到"
          :hidden="dialogStatus === 'info' ? false : true"
        >
          <el-radio-group v-model="form.toSn">
            <el-radio
              v-for="device in devices"
              :label="device.sn"
              :key="device.sn"
              :disabled="form.sn === device.sn"
              >{{ device.sn }}({{ device.name }})</el-radio
            >
          </el-radio-group>
        </el-form-item>

        <el-form-item :hidden="dialogStatus === 'info' ? false : true">
          <el-button type="primary" @click="handleClone('form')"
            >資料複製</el-button
          >
        </el-form-item>

        <el-form-item
          label="裝置功能"
          :hidden="dialogStatus === 'info' ? false : true"
        >
          <el-button
            type="primary"
            @click="handleDeleteAll()"
            :hidden="dialogStatus === 'info' ? false : true"
            >移除所有人臉資料</el-button
          >
          <el-button
            type="primary"
            @click="handleReboot()"
            :hidden="dialogStatus === 'info' ? false : true"
            >裝置重新啟動</el-button
          >
        </el-form-item>
      </el-form>

      <span
        slot="footer"
        class="dialog-footer"
        :hidden="dialogStatus === 'info' ? true : false"
      >
        <el-button type="danger" @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('form')"
          >確 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import swal from "sweetalert2";
import {
  Dialog,
  Button,
  Form,
  FormItem,
  Input,
  Radio,
  RadioGroup,
} from "element-ui";
import axios from "axios";

Vue.use(Dialog);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Radio);
Vue.use(RadioGroup);

export default {
  components: {},
  name: "setting-device-dialog",
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
    currentData: {
      type: Object,
      require: true,
      default: {},
    },
    dialogStatus: {
      type: String,
      require: true,
      defalut: "info",
    },
  },
  data() {
    return {
      form: {
        toSn: "",
        Device: {},
      },
      rules: {},
      devices: [],
      dispatchOptions: [
        {
          value: 1,
          label: "1",
        },
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
        {
          value: 5,
          label: "5",
        },
      ],
    };
  },
  created() {
    // this.form = { ...this.currentData };
    this.getDeviceInfo(this.currentData.id);
    console.log(this.form);
    this.getDevices();
  },
  methods: {
    getDevices() {
      axios.get(`/face/device`).then((res) => {
        const devicesSn = res.data.rows.map((item, index, array) => {
          return {
            sn: item.sn,
            name: item.name,
          };
          // return item.sn;
        });
        this.devices = devicesSn;
      });
    },
    getDeviceInfo(id) {
      axios.get(`/face/device/soil/info?id=${id}`).then((res) => {
        this.form = res.data;

        if (res.data.Device === null) {
          this.form.Device = {
            version_code: "",
            version_name: "",
          };
        }
      });
    },
    handleClose() {
      this.$emit("close-dialog", { visible: !this.visible });
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogStatus === "add") {
            axios.post(`/face/device/soil`, this.form).then((res) => {
              console.log(res.data);
            });
          }
          if (this.dialogStatus === "edit") {
            axios
              .put(`/face/device`, {
                id: this.form.id,
                name: this.form.name,
                soil_dispatch_id: this.form.Device.soil_dispatch_id,
                hauler_id: this.form.hauler_id,
              })
              .then((res) => {
                console.log(res.data);
              });
          }

          this.$emit("close-dialog", {
            visible: !this.visible,
            data: this.form,
          });
        }
      });
    },
    handleClone(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogStatus === "info") {
            this.handleClose();
            swal
              .fire({
                title: "是否執行資料複製",
                showDenyButton: true,
                confirmButtonText: "是",
                denyButtonText: "否",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  swal.fire("請求 Clone 開始!", "", "success");
                  axios
                    .post(`/face/device/clone`, {
                      fromSn: this.form.sn,
                      toSn: this.form.toSn,
                    })
                    .then(
                      (res) => {
                        this.$notify({
                          title: "執行 Clone 開始",
                          message: res.data.msg,
                          verticalAlign: "bottom",
                          icon: "nc-icon nc-check-2",
                          type: "success",
                        });
                        swal.fire("執行 Clone 開始!", "", "success");
                        this.$emit("close-dialog", {
                          visible: !this.visible,
                          data: this.form,
                        });
                      },
                      (res) => {
                        this.$notify({
                          title: "執行 Clone 失敗",
                          message: res.response.data.msg,
                          verticalAlign: "bottom",
                          icon: "nc-icon nc-alert-circle-i",
                          type: "danger",
                        });
                        swal.fire("執行 Clone 失敗", "", "error");
                        this.$emit("close-dialog", {
                          visible: !this.visible,
                          data: this.form,
                        });
                      }
                    );
                } else if (result.isDenied) {
                  swal.fire("資料複製尚未執行", "", "info");
                }
              });
          }
        }
      });
    },
    handleDeleteAll(formName) {
      if (this.dialogStatus === "info") {
        this.handleClose();
        swal
          .fire({
            title: "是否執行移除所有人臉",
            showDenyButton: true,
            confirmButtonText: "是",
            denyButtonText: "否",
          })
          .then((result) => {
            if (result.isConfirmed) {
              axios
                .delete(`/face/users`, {
                  data: {
                    sn: [this.form.sn],
                  },
                })
                .then(
                  (res) => {
                    this.$notify({
                      title: "執行移除所有人臉資料開始",
                      message: res.data.msg,
                      verticalAlign: "bottom",
                      icon: "nc-icon nc-check-2",
                      type: "success",
                    });
                    swal.fire("執行移除所有人臉資料開始!", "", "success");
                    this.$emit("close-dialog", {
                      visible: !this.visible,
                      data: this.form,
                    });
                  },
                  (res) => {
                    this.$notify({
                      title: "執行移除所有人臉資料失敗",
                      message: res.response.data.msg,
                      verticalAlign: "bottom",
                      icon: "nc-icon nc-alert-circle-i",
                      type: "danger",
                    });
                    swal.fire("執行移除所有人臉資料失敗", "", "error");
                    this.$emit("close-dialog", {
                      visible: !this.visible,
                      data: this.form,
                    });
                  }
                );
            } else if (result.isDenied) {
              swal.fire("移除所有人臉尚未執行", "", "info");
            }
          });
      }
    },
    handleReboot(formName) {
      if (this.dialogStatus === "info") {
        this.handleClose();
        swal
          .fire({
            title: "是否執行重新啟動裝置",
            showDenyButton: true,
            confirmButtonText: "是",
            denyButtonText: "否",
          })
          .then((result) => {
            if (result.isConfirmed) {
              axios
                .post(`/face/reboot`, {
                  sn: this.form.sn,
                })
                .then(
                  (res) => {
                    this.$notify({
                      title: "執行重新啟動裝置開始",
                      message: res.data.msg,
                      verticalAlign: "bottom",
                      icon: "nc-icon nc-check-2",
                      type: "success",
                    });
                    swal.fire("執行重新啟動裝置開始!", "", "success");
                    this.$emit("close-dialog", {
                      visible: !this.visible,
                      data: this.form,
                    });
                  },
                  (res) => {
                    this.$notify({
                      title: "執行重新啟動裝置失敗",
                      message: res.response.data.msg,
                      verticalAlign: "bottom",
                      icon: "nc-icon nc-alert-circle-i",
                      type: "danger",
                    });
                    swal.fire("執行重新啟動裝置失敗", "", "error");
                    this.$emit("close-dialog", {
                      visible: !this.visible,
                      data: this.form,
                    });
                  }
                );
            } else if (result.isDenied) {
              swal.fire("重新啟動裝置尚未執行", "", "info");
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
